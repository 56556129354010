@import './BoundaryEntriesDropdown/style';

.file-review-center-view {
  &-menu {
    padding: 0 1rem 0.5rem 1rem;
  }
  &-main {
    display: grid;
    padding: 0 20px 40px 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.1rem;
    height: calc(100vh - 96px); //This height is fix for the boundary detection: if this is affecting other components let's isolate them to avoid conflicting styles.
    overflow-y: auto;
  }
}
