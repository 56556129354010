.quality-control-create-boundary-right-view-v2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f2f1f5;

  .empty-warning {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    padding: 1rem;
    p {
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
    svg path {
      stroke: #3c3a41;
      stroke-width: 2;
    }
  }

  &__top-container {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    row-gap: 0.625rem;
    padding: 1rem;
    width: 100%;
    .taskcard__header {
      border-radius: 0.38rem;
    }
  }

  &__center-container__wrapper {
    overflow-y: auto;
    height: -webkit-fill-available;
  }

  &__center-container {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 0.5rem 1rem 1rem 1rem;
    width: 100%;
    height: 100%;

    &.disable {
      color: var(--Platform-Grayscale-40, #a2a1a8) !important;
      p {
        color: var(--Platform-Grayscale-40, #a2a1a8) !important;
      }
      svg {
        path {
          stroke: var(--Platform-Grayscale-40, #a2a1a8) !important;
        }
      }
    }

    &__form {
      &__save-button {
        display: flex;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        background: var(--platform-purple-basic, #7856ff);

        color: var(--platform-white, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        &:disabled {
          background: var(--platform-grayscale-10, #e5e4eb);
          color: var(--platform-grayscale-25, #cccad1);

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    &__change-container {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 0.5rem;
      width: 100%;

      &__change-button {
        display: flex;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        background: var(--Platform-Grayscale-5, #f2f1f5);

        color: var(--platform-grayscale-90, #3c3a41);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__toast-container {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1px;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      justify-content: flex-end;

      &__text {
        color: var(--color-system-success-primary, #06b54c);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: -0.13px;
      }
    }
  }

  &__bottom-container {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    row-gap: 0.625rem;
    padding: 1rem;
    width: 100%;
  }

  .textfield__input-container {
    border: none !important;
  }
}
