.phase-progress {
  display: flex;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
  background: var(--Platform-Grayscale-Hover, #fafafa);
  .phase-text-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .phase-title {
    color: var(--Platform-Grayscale-55, #838188);
    font-family: 'Helvetica Neue';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
  }
  .phase-view-btn{
    border-bottom: 1px solid;
    cursor: pointer;
  }
  .phase-text {
    width: 100%;
    color: var(--Platform-Grayscale-55, #838188);
    font-family: 'Helvetica Neue';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .stage-progress {
    display: flex;
    padding: 1rem 1.5rem;
    align-items: center;
    justify-content: space-between;
    gap: 2.2rem;
    border-radius: 0.375rem;
    background: var(--color-grayscale-10, #fff);
    .nota {
      text-align: center;
      width: 100%;
      color: var(--Platform-Grayscale-55, #838188);
      text-align: center;
      font-family: 'Helvetica Neue';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      margin: 10px 0;
    }
    .status-stage {
      .stage {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--Platform-Grayscale-55, #838188);
        font-family: 'Helvetica Neue';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        svg {
          margin-right: 10px;
          path {
            stroke: #838188;
          }
        }
      }
      .progress {
        color: var(--Platform-Grayscale-55, #838188);
        font-family: 'Helvetica Neue';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
      }
      &.active {
        .stage {
          color: var(--Platform-Grayscale-90, #3c3a41);
          svg path {
            stroke: #3c3a41;
          }
        }
        .progress {
          color: var(--Platform-Grayscale-90, #3c3a41);
        }
      }
    }
  }
  &.active {
    border: 1px solid var(--Platform-Purple-Basic, #7856ff);
    background: #fff;
    .phase-title {
      color: var(--Platform-Purple-Basic, #7856ff);
    }
    .phase-text {
      color: var(--Platform-Purple-Basic, #7856ff);
    }
    .stage-progress {
      background: #f8f8fb;
    }
  }
  &.done {
    border: 1px solid var(--Platform-Purple-Basic, #7856ff);
    background: var(--Platform-Purple-Basic, #7856ff);
    .phase-title {
      color: var(--Platform-Purple-Pastel, #d0c8f3);
    }
    .phase-text {
      color: var(--Platform-Purple-Pastel, #fff);
      svg path {
        stroke: #fff;
      }
    }
    .stage-progress {
      background: var(--Platform-Purple-Dark, #4d29db);
      .status-stage {
        .stage {
          color: var(--Platform-White, var(--color-grayscale-10, #fff));
          svg path {
            stroke: #fff;
          }
        }
        .progress {
          color: var(--Platform-White, var(--color-grayscale-10, #fff));
        }
      }
    }
  }
  &.disable {
    border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
    background: var(--Platform-Grayscale-5, #f2f1f5);
    .phase-title {
      color: var(--Platform-Grayscale-55, #838188);
    }
    .phase-text {
      color: var(--Platform-Grayscale-55, #838188);
      svg path {
        stroke: #838188;
      }
    }
    .stage-progress {
      background: var(--Platform-Grayscale-10, #e5e4eb);
    }
  }
}
