.quality-control-create-boundary-status-left-view {
  display: grid;
  grid-template-columns: 1fr;
  place-content: start;
  place-items: start;
  position: relative;
  width: 100%;
  height: 100%;

  &__empty {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    width: 100%;
    row-gap: 1rem;
    padding: 1rem;
    max-height: calc(100vh - 120px - 72px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &__title {
      color: var(--platform-black, #18161b);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
    }

    &__description {
      color: #838188;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  &__doctypes {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    width: 100%;
    row-gap: 0.5rem;
    padding: 1rem;
    max-height: calc(100vh - 120px - 72px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &__title {
      color: var(--platform-black, #18161b);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}
